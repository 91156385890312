import React from 'react';
const NotFoundIcon = () => (
  <svg width="27" height="24" viewBox="0 0 27 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19.573 14.6667H18.5196L18.1463 14.3067C19.453 12.7867 20.2396 10.8133 20.2396 8.66667C20.2396 3.88 16.3596 0 11.573 0C7.01296 0 3.27962 3.52 2.94629 8H5.63962C5.97296 5 8.47962 2.66667 11.573 2.66667C14.893 2.66667 17.573 5.34667 17.573 8.66667C17.573 11.9867 14.893 14.6667 11.573 14.6667C11.3463 14.6667 11.133 14.6267 10.9063 14.6V17.2933C11.133 17.32 11.3463 17.3333 11.573 17.3333C13.7196 17.3333 15.693 16.5467 17.213 15.24L17.573 15.6133V16.6667L24.2396 23.32L26.2263 21.3333L19.573 14.6667Z"
      fill="#A6A6A6"
    />
    <path
      d="M7.53333 10.4268L4.24 13.7201L0.946667 10.4268L0 11.3734L3.29333 14.6668L0 17.9601L0.946667 18.9068L4.24 15.6134L7.53333 18.9068L8.48 17.9601L5.18667 14.6668L8.48 11.3734L7.53333 10.4268Z"
      fill="#A6A6A6"
    />
  </svg>
);

export default NotFoundIcon;
