import React from 'react';
interface TelegramLogoProps {
  width?: string;
  height?: string;
}

export const TelegramLogo = ({ width, height }: TelegramLogoProps) => (
  <svg
    width={width || '52'}
    height={height || '52'}
    viewBox="0 0 52 52"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="52" height="52" rx="12" fill="#4BADE4" />
    <path
      d="M11.8392 24.3029L29.7092 16.9402C31.4732 16.1732 37.4554 13.719 37.4554 13.719C37.4554 13.719 40.2165 12.6453 39.9864 15.2529C39.9097 16.3266 39.2961 20.0847 38.6826 24.1495L36.7652 36.1907C36.7652 36.1907 36.6118 37.9547 35.308 38.2615C34.0041 38.5683 31.8567 37.1877 31.4732 36.881C31.1664 36.6509 25.7211 33.1996 23.727 31.5123C23.1901 31.0521 22.5766 30.1318 23.8037 29.058C26.5647 26.5271 29.8626 23.3826 31.8567 21.3885C32.777 20.4682 33.6974 18.3207 29.8626 20.9283L19.0486 28.2144C19.0486 28.2144 17.8215 28.9813 15.5206 28.2911C13.2197 27.6008 10.5354 26.6805 10.5354 26.6805C10.5354 26.6805 8.69472 25.5301 11.8392 24.3029Z"
      fill="white"
    />
  </svg>
);
